<template>
  <v-card elevation="0">
    <v-container cols="12" class="pt-1 pb-1">
      <v-row>
        {{ $t('shipment.parcels.scan-parcel-code') }}
      </v-row>
      <validation-observer ref="observer">
        <v-row class="mb-0 pb-0">
          <v-col cols="12" class="mb-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ numeric: false }"
              :name="$t('materials.part-id')"
            >
              <v-text-field
                ref="input"
                v-model="scanned"
                :label="$t('materials.part-id')"
                :error-messages="errors"
                @keydown.esc="onClose"
                @keydown.enter="onAdd"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-1 pt-1 mb-5">
          <v-col col="12" sm="6" md="3" class="mt-0 pt-0">
            <v-icon
              medium
              @click="
                () => {
                  keybordVisible = !keybordVisible;
                }
              "
            >
              {{ keybordVisible ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            <v-icon
              medium
              @click="
                () => {
                  keybordVisible = !keybordVisible;
                }
              "
            >
              mdi-keyboard
            </v-icon>
            <SimpleKeyboard
              v-if="keybordVisible"
              :key="keyboardEpoch"
              keyboard-class="scanned"
              :input="scanned"
              :layout="numeric"
              @onChange="
                input => {
                  scanned = input;
                }
              "
            />
          </v-col>
        </v-row>
      </validation-observer>

      <materials
        :order="order"
        :epoch="childrenEpoch"
        @removed="childrenEpoch++"
      />
      <order-logs
        :item="order"
        :epoch="childrenEpoch"
        :title="$t('materials.log')"
        :contexts="['materials']"
      />
    </v-container>
  </v-card>
</template>

<script>
import materials from '@/views/dashboard/components/orders/materialList.vue';
import OrderService from '@/services/OrderService.js';
import OrderLogs from '@/views/dashboard/components/orders/orderLog.vue';
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';
import numeric from '@/views/operator/components/NumericLayout.js';

export default {
  components: {
    materials,
    OrderLogs,
    SimpleKeyboard
  },
  mixins: [keyboardMixin],
  props: {
    order: {
      type: Object,
      required: true
    },
    epoch: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      scanned: '',
      childrenEpoch: 0,
      focusInterval: null,
      keybordVisible: false,
      numeric: numeric
    };
  },
  watch: {
    epoch() {
      this.focus();
    }
  },
  mounted() {
    console.log('mounted');
    this.focus();
  },
  activated() {
    console.log('activated');
    this.focus();
  },
  methods: {
    async onAdd() {
      console.log('add:', this.scanned);
      const result = await this.$refs.observer.validate();
      if (!result) {
        return;
      }
      try {
        const part_id = this.scanned.trim();
        await OrderService.AddMaterialPart(this.order, part_id);
        this.childrenEpoch++;
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      }
      this.scanned = '';
    },
    focus() {
      console.log('focus:', this.$refs.parcel);
      setTimeout(() => {
        this.$refs.input.focus();
      }, 200);
    }
  }
};
</script>

<style></style>
