var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-container',{staticClass:"pt-1 pb-1",attrs:{"cols":"12"}},[_c('v-row',[_vm._v(" "+_vm._s(_vm.$t('shipment.parcels.scan-parcel-code'))+" ")]),_c('validation-observer',{ref:"observer"},[_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{ numeric: false },"name":_vm.$t('materials.part-id')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{ref:"input",attrs:{"label":_vm.$t('materials.part-id'),"error-messages":errors},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onClose.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onAdd.apply(null, arguments)}]},model:{value:(_vm.scanned),callback:function ($$v) {_vm.scanned=$$v},expression:"scanned"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1 pt-1 mb-5"},[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"col":"12","sm":"6","md":"3"}},[_c('v-icon',{attrs:{"medium":""},on:{"click":() => {
                _vm.keybordVisible = !_vm.keybordVisible;
              }}},[_vm._v(" "+_vm._s(_vm.keybordVisible ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('v-icon',{attrs:{"medium":""},on:{"click":() => {
                _vm.keybordVisible = !_vm.keybordVisible;
              }}},[_vm._v(" mdi-keyboard ")]),(_vm.keybordVisible)?_c('SimpleKeyboard',{key:_vm.keyboardEpoch,attrs:{"keyboard-class":"scanned","input":_vm.scanned,"layout":_vm.numeric},on:{"onChange":input => {
                _vm.scanned = input;
              }}}):_vm._e()],1)],1)],1),_c('materials',{attrs:{"order":_vm.order,"epoch":_vm.childrenEpoch},on:{"removed":function($event){_vm.childrenEpoch++}}}),_c('order-logs',{attrs:{"item":_vm.order,"epoch":_vm.childrenEpoch,"title":_vm.$t('materials.log'),"contexts":['materials']}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }