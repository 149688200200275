import request from './request'
import mixin from '../mixin'

export default {
  async getOrderLogs(orderID) {
    let url = `/api/orders/${orderID}/logs/`
    let logs = await request.allPages(url, url => {
      return request.request(url, 'get', {}, {})
    })

    return logs.map(l => {
      l.created_at = mixin.methods.dateFromISO8601(l.created_at)
      return l
    }, this)
  }
}
