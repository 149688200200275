<template>
  <v-card elevation="0">
    <v-card-title>{{ cardTitle }}</v-card-title>

    <v-data-table
      v-if="logs"
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="filteredLogs"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
    >
      <template #[`item.created_at`]="{ item }">
        <span>{{ timestamp(item) }}</span>
      </template>
      <template #[`item.severity`]="{ item }">
        <div :class="getSeverityColor(item)" class="pa-2">
          {{ item.severity }}
        </div>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="onClose">
        {{ $t('close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LogService from '@/services/LogService.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    epoch: {
      type: Number,
      required: false,
      default: 0
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    contexts: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function() {
    return {
      logs: null,
      loading: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('log.timestamp'),
          value: 'created_at',
          sortable: false,
          width: '12%'
        },
        {
          text: this.$t('log.severity'),
          value: 'severity',
          sortable: false,
          width: '7%'
        },
        {
          text: this.$t('user.user'),
          value: 'user',
          sortable: false,
          width: '7%'
        },
        {
          text: this.$t('log.message'),
          value: 'msg',
          sortable: false
        }
      ];
    },
    cardTitle() {
      return (
        this.title ||
        `${this.$t('orders.order')} ${this.item.order_no} - ${this.$t(
          'log.log'
        )}`
      );
    },
    filteredLogs() {
      if (this.contexts.length == 0) {
        return this.logs;
      }
      return this.logs.filter(l => this.contexts.includes(l.context));
    }
  },
  watch: {
    async item() {
      await this.load();
    },
    async epoch() {
      await this.load();
    }
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      this.loading = true;
      try {
        console.log('loading logs for order:', this.item.order_no);
        this.logs = await LogService.getOrderLogs(this.item.id);
        console.log('logs:', this.logs);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    timestamp(item) {
      if (!item.created_at) return '';
      return item.created_at.toLocaleString();
    },
    getSeverityColor(item) {
      if (!item) return 'red';
      switch (item.severity) {
        case 'INFO':
          return 'green';
        case 'ERROR':
          return 'red';
        case 'WARNING':
          return 'orange';
      }
      return '';
    }
  }
};
</script>

<style></style>
